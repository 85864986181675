/**
 * @ComponentFor StartPageViewModel
 */
import React from 'react';
import { EpiProperty } from '@avensia/scope-episerver';
import PropType from './StartPageViewModel.type';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';

export default (props: PropType) => (
  <Main appearance={PageAppearance.Narrow}>
    <Section>
      <EpiProperty for={props.page.mainBody} />
    </Section>
  </Main>
);
